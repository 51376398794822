import { useEffect, useState } from 'react';
import { Button } from '../Button';
import {
  adminStartExport,
  adminExportStatus,
  adminExportDownload,
} from 'api/transport';
import {
  ExportType,
  ExportStatus,
  CurrentUser,
} from 'types';
import { userCan } from 'utils/permissions';

export type AdminExportButtonProps = {
  type: ExportType;
  exportParams?: object;
  user?: CurrentUser;
  permission?: string;
};

export const AdminExportButton = ({ type, permission, user, exportParams = {} }: AdminExportButtonProps) => {
  const [exportStatus, setExportStatus] = useState<ExportStatus | null>(null);
  const [requestInProcess, setRequestInProcess] = useState(false);
  const [checkStatusInterval, setCheckStatusInterval] = useState();

  useEffect(() => {
    fetchExportStatus().then();
  }, [type]);

  useEffect(() => {
    if (exportStatus !== 'in_process' && checkStatusInterval) {
      clearInterval(checkStatusInterval);
    }

    if (exportStatus === 'in_process') {
      setCheckStatusInterval(
        setInterval(fetchExportStatus, 10000)
      );
    }
  }, [exportStatus]);

  const userCanExport = () => {
    return !user || !permission || userCan(user, permission);
  }

  const fetchExportStatus = async () => {
    if (userCanExport()) {
      const response = await adminExportStatus(type);

      if (response.ok) {
        setExportStatus(response.status);
      }
    }
  }

  const handleStartExport = async () => {
    const response = await adminStartExport(type, exportParams);

    if (response.ok) {
      setExportStatus('in_process');
    }
  }

  const handleDownload = async () => {
    await adminExportDownload(type);
    await fetchExportStatus();
  }

  const handleButtonClick = async () => {
    setRequestInProcess(true);

    if (exportStatus === 'ready_to_download') {
      await handleDownload();
    } else if (exportStatus === 'not_started') {
      await handleStartExport();
    }

    setRequestInProcess(false);
  }

  return (
    <>
      {exportStatus && (
        <Button
          caption={exportStatus === 'ready_to_download' ? 'Download Export' : 'Export'}
          disabled={requestInProcess || exportStatus === 'in_process'}
          withSpinner={exportStatus === 'in_process'}
          iconSrc={exportStatus === 'in_process' ? undefined : '/icons/design/DownloadSimpleBlack.svg'}
          buttonType="white"
          shape="auto"
          action={handleButtonClick}
        />
      )}
    </>
  );
};
